import Avatar, { AvatarGroupProps } from "@/components/primitives/avatar";

export type PickGroupProps = Omit<AvatarGroupProps, 'value'> & { totalPicksForSale: number; totalBettors: number };

function PickGroup(
  props: PickGroupProps
) {
  return (
    <Avatar.Group
    {...props}
      avatars={props.avatars}
      label={`${props.totalPicksForSale} picks for sale`}
      value={props.totalBettors}
    />
  );
}

export default PickGroup;
