"use client";
import * as RadixTabs from "@radix-ui/react-tabs";
import { useRouter } from "next/navigation";
import Icon from "@/components/primitives/icon";
import Button from "./button";
import { Option } from "./select";

export interface TabsProps extends RadixTabs.TabsProps {
  label: string;
  items: TabItem[];
  variant?: "pill" | "dark" | "pill-sm";
  dark?: boolean;
  fixedWidth?: number;
  block?: boolean;
  buttonWidth?: number;
}

export interface TabItem extends Option {
  content?: JSX.Element;
}

function Tabs(props: TabsProps) {
  if (props.variant === "dark") {
    return (
      <RadixTabs.Root {...props} className="relative flex flex-col md:flex-row bg-secondary-500 bg-odds-tab rounded-xl text-white xl:pr-10 overflow-hidden">
        <div className="flex flex-col gap-3 p-4 md:p-6 lg:py-12 lg:pl-10 lg:pr-8 md:w-[236px] md:min-w-[236px] lg:w-[296px] lg:min-w-[296px] md:border-r lg:min-h-[468px] border-[#2D3034]">
          <RadixTabs.List aria-label={props.label} className="flex flex-col gap-3">
            {props.items.map((item, i) => (
              <RadixTabs.Trigger
                key={i}
                value={item.value}
                className="flex flex-row whitespace-nowrap items-center justify-between px-4 py-2 lg:pl-8 lg:py-6 lg:pr-4 text-title-lg-bold lg:text-headline-bold uppercase text-left rounded-lg text-white hover:text-secondary-300 bg-transparent h-12 md:h-[4.5rem] ease-in-out duration-200 data-[state=active]:bg-primary-500 data-[state=active]:text-secondary-300 data-[state=active]:hover:bg-primary-600 hover:bg-primary-600"
              >
                {item.label} {item.count ? `(${item.count})` : undefined}
                <Icon name="arrow-right" size="sm"/>
              </RadixTabs.Trigger>
            ))}
          </RadixTabs.List>
        </div>
        {props.items.map((item, i) => (
          <RadixTabs.Content key={i} value={item.value} className="data-[state=active]:flex">
            {item.content}
          </RadixTabs.Content>
        ))}
      </RadixTabs.Root>
    );
  } else if (props.dark) {
    return (
      <RadixTabs.Root {...props} className={`${props.block ? "w-full" : undefined} flex flex-col items-end`}>
        <div className={`${props.block ? "w-full" : undefined} ${props.variant === "pill-sm" ? "h-7 py-0.5 border-[2px] border-white px-0 bg-transparent" : "h-11 p-1 bg-secondary-700" } inline-flex flex-row  rounded-lg items-center justify-center p-1 h-11`}>
          <RadixTabs.List aria-label={props.label} className="flex w-full">
            {props.items.map((item, i) => (
              <RadixTabs.Trigger
                key={i}
                value={item.value}
                className={`${props.block ? "w-full" : undefined} ${props.variant === "pill-sm" ? "text-label-medium h-6 uppercase text-dark" : "text-title-medium uppercase py-2 h-9"} px-4 text-white rounded-[8px] bg-transparent ease-in-out duration-200 data-[state=active]:bg-white data-[state=active]:text-dark hover:bg-white/50 whitespace-nowrap`}
                style={{minWidth: props.buttonWidth ? `${props.buttonWidth}px` : undefined, width: props.buttonWidth ? `${props.buttonWidth}px` : `${props.block ? "100%" : "auto"}`}}
              >
                {item.label} {item.count ? `(${item.count})` : undefined}
              </RadixTabs.Trigger>
            ))}
          </RadixTabs.List>
        </div>
        {props.items.map((item, i) => (
          <RadixTabs.Content key={i} value={item.value} className="w-full">
            {item.content}
          </RadixTabs.Content>
        ))}
      </RadixTabs.Root>
    );
  } else {
    return (
      <RadixTabs.Root {...props} className={`${props.block ? "w-full" : undefined} flex flex-col items-end`}>
        <div className={`${props.block ? "w-full" : undefined} ${props.variant === "pill-sm" ? (props.dark ? "h-7 p-0.5 bg-transparent" : "h-7 p-0.5 bg-surface-600") : "h-11 p-1 bg-secondary-700" } inline-flex flex-row  rounded-lg items-center justify-center p-1 h-11`}>
          <RadixTabs.List aria-label={props.label} className="flex w-full">
            {props.items.map((item, i) => (
              <RadixTabs.Trigger
                key={i}
                value={item.value}
                className={`${props.block ? "w-full" : undefined} ${props.variant === "pill-sm" ? (props.dark ? "text-label-medium h-6 text-white" : "text-label-medium h-6 uppercase text-dark") : "text-title-medium uppercase py-2 h-9"}   px-4  rounded-[8px] bg-transparent ease-in-out duration-200 data-[state=active]:bg-white hover:bg-white/50 whitespace-nowrap`}
                style={{minWidth: props.buttonWidth ? `${props.buttonWidth}px` : undefined, width: props.buttonWidth ? `${props.buttonWidth}px` : `${props.block ? "100%" : "auto"}`}}
              >
                {item.label} {item.count ? `(${item.count})` : undefined}
              </RadixTabs.Trigger>
            ))}
          </RadixTabs.List>
        </div>
        {props.items.map((item, i) => (
          <RadixTabs.Content key={i} value={item.value} className="w-full">
            {item.content}
          </RadixTabs.Content>
        ))}
      </RadixTabs.Root>
    );
  }
}

interface TabsFilterProps {
  dependency: string;
  options: TabsFilterOption[];
}

export interface TabsFilterOption extends Option {
  push: URLSearchParams;
}

function Filter(props: TabsFilterProps) {
  const router = useRouter();
  return (
    <div className="flex flex-row bg-secondary-700 rounded-lg items-center justify-center p-1 h-11">
      {props.options.map((option, i) => (
        <Button
          key={i}
          className="text-title-medium uppercase px-4 h-9 rounded-[8px] bg-transparent data-[state=active]:hover:scale-110 data-[state=active]:bg-white"
          onClick={() =>
            router.push(
              `?${new URLSearchParams({
                view: option.value,
              })}`,
              { scroll: false }
              )
          }
          data-state={option.value === props.dependency ? "active" : undefined}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );
}
Tabs.Filter = Filter;
export default Tabs;