import { Sport, TeamBasic } from "common";
import TeamLogo from "./logo";
import Link from "next/link";
import { createTeamSlug, formatNameToUrl } from "@/utils/url";

export interface TeamDisplayProps {
  team: TeamBasic;
  sport?: Sport;
  variant: TeamDisplayVariant;
  size?: TeamDisplaySize;
  orientation?: "horizontal" | "vertical";
  dark?: boolean;
}
type TeamDisplayVariant =
  | "abbreviation"
  | "abbreviation-name"
  | "name"
  | "location-name";
type TeamDisplayVariance = {
  [key in TeamDisplayVariant]: MappedTeamDisplaySize;
};
export type TeamDisplaySize = "xs" | "sm" | "md" | "lg";
export type MappedTeamDisplaySize = {
  [key in TeamDisplaySize]: string;
};
const teamDisplayVariance: TeamDisplayVariance = {
  abbreviation: {
    xs: "gap-1",
    sm: "gap-1",
    md: "gap-2",
    lg: "gap-3",
  },
  "abbreviation-name": {
    xs: "gap-2",
    sm: "gap-2",
    md: "gap-3",
    lg: "gap-3",
  },
  name: {
    xs: "gap-2",
    sm: "gap-2",
    md: "gap-3",
    lg: "gap-3",
  },
  "location-name": {
    xs: "gap-3",
    sm: "gap-3",
    md: "gap-4",
    lg: "gap-4",
  },
};
export default function TeamDisplay(props: TeamDisplayProps) {
  const classes: string = `flex ${
    props.orientation === "vertical" ? "flex-col" : "flex-row"
  } ${props.dark ? "text-white" : "text-secondary-100"} ${teamDisplayVariance[props.variant][props.size || "md"]}`;
  if (props.sport) {

    return (
      <Link href={createTeamSlug(props.sport, props.team)} className={classes}>
      <TeamLogo team={props.team} size={props.size || "md"} />
      <div className="flex flex-row items-center gap-1 capitalize">
        {props.variant === "abbreviation" ? (
          <span className="text-title-lg">{props.team.abbreviation}</span>
        ) : (
          <>
            <span className="text-title-lg-medium">{props.team.location}</span>

            <span className="text-title-lg">{props.team.name}</span>
          </>
        )}
      </div>
    </Link>
  );
}
return (
  <>
  <TeamLogo team={props.team} size={props.size || "md"} />
      <div className="flex flex-row items-center gap-2 capitalize">
        {props.variant === "abbreviation" ? (
          <span className="text-title-lg">{props.team.abbreviation}</span>
        ) : (
          <>
            <span className="text-title-lg-medium">{props.team.location}</span>

            <span className="text-title-lg">{props.team.name}</span>
          </>
        )}
      </div>
  </>
)
}
