import { Injury, ApiResponse, Sport, TeamInjuries } from "common";



export async function getInjuries(sport: Sport, teamOne?: string, teamTwo?: string): Promise<ApiResponse<Injury[]>> {
  const baseUrl: string = `${process.env.NEXT_PUBLIC_API_URL}/api/sports/data/injuries/${sport}`;
  const params: URLSearchParams = new URLSearchParams();
  if (teamOne && teamTwo) {
    params.append("teamKey", `${teamOne},${teamTwo}`);
  } else if (teamOne) {
    params.append("teamKey", teamOne);
  }
  const request: string = `${baseUrl}?${params}`;

  try {
    const res = await fetch(request, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      cache: "no-store",
    });

    if (!res.ok) {
      return { status: "error", message: `Failed to fetch injuries: ${res.status} ${res.statusText}` };
    }
    
    const data: Injury[] = await res.json();
    return { status: "success", data };
  } catch (error) {
    return { status: "error", message: `Network error: ${error}` };
  }
}

export async function getInjuriesByGameId(sport: Sport) {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/api/sports/data/injuries/${sport}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      cache: "no-store",
    }
  );
  if (!res.ok) {
    throw new Error("Failed to fetch injuries");
  }
  return res.json();
}

export function formatNameToInitials(fullName: string): string {
  const [firstName, ...lastName] = fullName.trim().split(/\s+/);
  if (!firstName || lastName.length === 0) return fullName;
  const initial = firstName.charAt(0).toUpperCase();
  return `${initial}. ${lastName.join(" ")}`;
}

export function truncateName(input: string): string {
  const initial = input.charAt(0).toUpperCase();
  return `${initial}.`;
}

